.header {
    position: fixed;
    top: 0px;
    z-index: 10;
    width: 100%;
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: space-evenly;
    padding: 8px;
    align-items: center;
}

.header_links {
    display: flex;
    background: #ffffff2e;
    padding: 10px 10px;
    border-radius: 41px;
    /* box-shadow: 0px 0px 1px 0px #ffffff; */
}

.header_links .h_link_active {
    background: #9f9f9f42;
    padding: 8px 0px;
    border-radius: 20px;
    margin: 0px 5px;
}
.header_links .h_link {
    
    padding: 8px 0px;
    border-radius: 20px;
    margin: 0px 5px;
    cursor: pointer;
}
.header_links .h_link:hover {
    transition: 0.5s;
    background: #9f9f9f42;
    padding: 8px 0px;
    border-radius: 20px;
    margin: 0px 5px;
}

.header_links div a {
    color: rgb(0, 0, 0);
    margin: 9px 32px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;
}

.header_btn {
    background: #00000038;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 15px;
    box-shadow: 0px 0px 2px 0px white;
    text-transform: uppercase;
}
@media (max-width:1024px) {
    .header_links {
        display: none;
    }
}