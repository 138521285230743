.cont_detials div{
    margin: 20px;
    width: 300px;
    background: #3232326e;
    padding: 20px;
    border-radius: 20px;
}
.cont_detials div p a{
    text-decoration: none;
    color: white;
}

.cont_detials div p a:hover{
    text-decoration:underline;
    color: rgb(122, 122, 122);
}
.footer_sec{
    width: 1100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #2a2a2a6b;
    margin-top: 100px;
    border-radius: 20px;
    padding: 25px;
}
.footer_bottom{
    width: 100%; height: 100px; border-top: 1px solid rgb(116, 116, 116); margin-top: 30px;
}
.cont_logo{
    width: 50px;
    border-radius: 9px;
    height: 50px;
    margin: 5px;
}
