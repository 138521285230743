

.hero_sec {
    margin-top: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero_qout {
    width: 1000px;

}

.hero_qout span {
    font-weight: bold;
    font-size: 110px;
    font-family: "Acme", sans-serif;
}

.hero_para {
    width: 604px;
    color: gray;
    padding-bottom: 10px;
}

.hero_btn {
    padding: 12px;
    background: transparent;
    border: none;
    box-shadow: 0px 0px 2px 0px white;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
}

.team_sec {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 600px;
    background: linear-gradient(179deg, #ffffff, #5e5e5e);
}

.team_bage {
    width: 300px;
    padding: 25px;
    border: 1px solid #ffffff45;
    border-radius: 24px;
    margin-top: -67px;
    margin-left: 77px;
    position: relative;
    backdrop-filter: blur(23px);
}





.bubbles_container{
    position: relative;
    width: 100%;
    height: 700px;
    overflow: hidden;
}
.bubbles{
    position: relative;
    display: flex;
    justify-content: space-evenly;
}
.bubbles span{
    position: relative;
    width: 10px;
    height: 10px;
    background: #cbf5ff;
    /* background: #4fc3dc; */
    margin: 0 4px;
    border-radius: 50%;
    box-shadow: 0 0 20px 5px #4fc3dc44;
    animation: animate  15s linear infinite;
    animation-duration: calc(125s/ var(--i));
}
.bubbles span:nth-child(even){
    background: #ff682d;
    box-shadow: 0 0 20px 5px #dc874f44;
}
@keyframes animate {
    0%{
        transform: translateY(700px) scale(0);

    }100%{
        transform: translateY(-10vh) scale(1);
    }
}


.team_scroll{
    display: flex;
    justify-content: flex-start;
    background-color: transparent;
    overflow: hidden;
}
.team_scroll h1{
    font-size: 100px;
    text-transform: uppercase;
    color: #f24626;
    white-space: nowrap;
    padding: 0 6rem;
    letter-spacing: 1px;
    animation: move-rtl 6000ms linear infinite;
    font-family: Acme, sans-serif;
}
@keyframes move-rtl {
    0%{
        transform: translateX(0);
    }100%{
        transform: translateX(-100%);
    }
}




.button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    background: #ffffff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  
  .button-85:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  @keyframes glowing-button-85 {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  .button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffffe5;
    left: 0;
    top: 0;
    border-radius: 10px;
  }
  .team_image{
    height: 600px;
    position: absolute;
    
  }

  @media (max-width:1024px) {
    .hero_qout span {
        font-weight: bold;
        font-size: 90px;
        font-family: "Acme", sans-serif;
    }
    .hero_qout {
        width: 100%;
    }
    .team_image{
        height: 600px;
      width: 100%;
        
      }
}
@media (max-width:834px) {
    .hero_qout span {
        font-weight: bold;
        font-size: 73px;
        font-family: "Acme", sans-serif;
    }
}
@media (max-width:768px) {
    .hero_para {
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
        color: gray;
        padding-bottom: 10px;
    }
}

@media (max-width:480px) {
    .hero_para {
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
        color: gray;
        padding-bottom: 10px;
        margin-top: 11px;
        margin-bottom: 18px;
    }
    .hero_qout span {
        font-weight: bold;
        font-size: 67px;
        font-family: "Acme", sans-serif;
    }
}