.scroller {
    max-width: 1100px;
  }
  .scroller_contaienr{
    display: flex; justify-content: center; margin-bottom: 120px; width: 100%; overflow: hidden;
  }
  
  .scroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .scroller[data-animated="true"] {
    overflow: hidden;
    -webkit-mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  }
  
  .scroller[data-animated="true"] .scroller__inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 40s)
      var(--_animation-direction, forwards) linear infinite;
  }
  
  .scroller[data-direction="right"] {
    --_animation-direction: reverse;
  }
  
  .scroller[data-direction="left"] {
    --_animation-direction: forwards;
  }
  
  .scroller[data-speed="fast"] {
    --_animation-duration: 20s;
  }
  
  .scroller[data-speed="slow"] {
    --_animation-duration: 60s;
  }
  
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 0.5rem));
    }
  }

  :root {
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-primary-100: hsl(205, 15%, 58%);
    --clr-primary-400: hsl(215, 25%, 27%);
    --clr-primary-800: hsl(217, 33%, 17%);
    --clr-primary-900: hsl(218, 33%, 9%);
  }
  

  .tag-list {
    margin: 0;
    padding-inline: 0;
    list-style: none;
  }
  
  .tag-list li {
    padding: 1rem;
    background: #dbdbdb94;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
  }
  


.brand_container{
    box-shadow: 0px 0px 18px 1px #6e6e6e;
    background-repeat: no-repeat;
    background-attachment: fixed;   
    background-size: 100% 100%; 
    padding: 50px; 
    /* background-image: url('https://framerusercontent.com/images/xOz91BrLMifZXksr8HqpgNMqqEc.jpg?scale-down-to=1024'); */
    background-image: url('https://d39l2hkdp2esp1.cloudfront.net/img/photo/138897/138897_00_2x.jpg');
}

  .brand_sec .brand_bolbe .brand_item{
    width: 180px;
    height: 181px;
    margin: 20px;
    background: #ffffff00;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    backdrop-filter: blur(25px);
    margin-left: -33px;
    flex-direction: column;
    box-shadow: 0px 0px 13px 0px #0000005c;
  }
  .brand_bolbe{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: -10px;
  }
.barnd_card div{
    width: 240px;
    background: #ff59009e;
    text-align: center;
    padding: 10px;
    border-radius: 12px;
    backdrop-filter: blur(22px);
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 131px;
}
.barnd_card{
    display: flex;
    justify-content: center;
    margin-bottom: -40px;
}

.brand_per_card{
    width: 400px;
    backdrop-filter: blur(25px);
    border-radius: 20px;
    background: #ffffff00;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    box-shadow: 0px 0px 6px #0000008f;
    margin: 10px;
}
.brand_per_card span{
    background: #00000014;
    width: 65px;
    border-radius: 10px;
    padding: 14px;
    margin: 19px 3px;
    border: 1px solid;
}
.brand_per_card h3{
    font-size: 25px;
   
    font-weight: 300;
    margin-bottom: 11px;
}
.brand_per_card p{
    color: #000000d4;
    font-size: 14px;
    margin-bottom: 11px;

}
.brand_per_card {
  margin: 10px;
}

@media (max-width:1024px) {
  .brand_per_card {
      width: 291px;
      padding: 9px;
  }
}
@media (max-width:768px) {
  .brand_bolbe .brand_item:first-child {
      display: none;
  }
  .brand_bolbe .brand_item:last-child {
      display: none;
  }
  .brand_per_card:last-child {
      display: none;
  }
  .brand_per_card:first-child {
      display: none;
  }
  .barnd_card div:first-child {
      display: none;
  }
  .barnd_card div:last-child {
      display: none;
  }
  
}