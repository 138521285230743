
.More_sectionss{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.more_faq_cont{
    width: 1000px;
    box-shadow: 0px 0px 1px 0px #202020;
    padding: 4px;
    border-radius: 5px;
    margin: 10px;
    transition: 0.5s;
    padding: 20px;
    background: #00000014;
}
.more_faq_cont:hover h3{
    transition: 0.5s;
    color: #7a7a7a;
}
.more_faq_cont:hover span{
    transition: 0.5s;
    color: #8b8b8b;
}
.more_faq_cont p{
  
    padding-top: 0px;
    margin-top: 1px;
    margin-top: 25px;
    color: #000000;
    font-family: system-ui;
}
.qaa_drop{
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.disnone{
    display: none;
}
@media (max-width: 1024px) {


    .more_faq_cont {
        width: 90%;
    }
}