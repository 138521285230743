

.ser_head {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.ser_head_t {
    background: #000000;
    padding: 2px 17px;
    border-radius: 10px;
    font-size: 15px;
    color: #f6472d;
    text-transform: uppercase;
    font-weight: bold;
    font-family: monospace;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 18px;
    margin-bottom: 5px;
    text-align: center;
    /* box-shadow: 0px 0px 5px 0px #ff8400; */
}

.ser_card {
    background: #d7d7d7;
    width: 600px;
    padding: 30px;
    margin: 10px;
    border-radius: 23px;
}

.ser_card_icon {
    background: #ffffff69;
    padding: 16px;
    color: #fc452c;
    border-radius: 18px;
    text-shadow: 0px 0px 1px #ffa700;
    border: 1px solid;
    box-shadow: 0px 0px 20px 0px white;
    font-size: 35px;
  
}

.ser_card p {
    margin-top: 19px;
    color: black;
    font-size: 16px;
    font-family: system-ui;
}

.ser_contant {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}