* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fira Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(107, 107, 107);
}

body {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}