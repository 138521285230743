@media (max-width:1024px) {
  .brand_per_card {
      width: 291px;
  }
}
@media (max-width:834px) {
  .hero_qout span {
      font-weight: bold;
      font-size: 73px;
      font-family: "Acme", sans-serif;
  }
}
@media (max-width:768px) {
  .home .red:first-child {
      border: 1px solid red;
  }
  
}
@media (max-width:480px) {
  .hero_para {
      margin-left: 10px;
      margin-right: 10px;
      width: 100%;
      color: gray;
      padding-bottom: 10px;
      margin-top: 11px;
      margin-bottom: 18px;
  }
}